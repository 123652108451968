import { WelcomePage, LoginComponent, ForgotComponent, ChangePwdComponent } from './';

export default {
  path: 'sign-in',
  component: WelcomePage,
  childRoutes: [
    { path: '', component: LoginComponent, isIndex: true},
    { path: 'out', component: LoginComponent},
    { path: 'forgot', component: ForgotComponent },
    { path: 'change-password/:token', component: ChangePwdComponent }
  ]
};
