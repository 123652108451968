import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Layer, Box, Heading, Button, FormField, TextInput } from 'grommet';
import BusinessService from "../../services/business.service";
import { presentToastErrorContent,  } from '../common/Toast';
import { presentLoading, dismissLoading } from '../common/Loading';


export default function BusinessModal(props) {

  const { onClose } = props;
  const [ slug, setSlug] = useState("");
  const [ slugError, setSlugError ] = useState("");

  function handleSlugChange({target:{value}}) 
  {
    setSlug(value);
  }

  const handleAdd = () =>
  {
      presentLoading();
      BusinessService.addBusiness({slug}).then(
          (response) => {
            dismissLoading();
            const business = response.data;
            window.location.href = "/business/" + business._id;
          },
          (error) => {
            console.log(error);
            if (error.status === 409)
            {
              setSlugError("This slug is already taken. Try another one.");
            }
            else
            {
              dismissLoading();
              presentToastErrorContent(error);
            }
          }
        );
  }


  return (
    <Layer position="center" modal onEsc={onClose} >
      <Box pad="medium" gap="small" width="medium">
        <Heading level={3} margin="none"> Add Hair Stylist </Heading>
        <Box direction="row" gap="small">
        <FormField label="Enter a slug" error={slugError} fill>
          <TextInput value={slug} onChange={handleSlugChange} />
        </FormField>
        </Box>
        <Box direction="row" justify="between" margin={{ top: "medium" }}>
          <Button label="Cancel" onClick={onClose} />
          <Button label="Save" onClick={handleAdd} primary/>
        </Box>
      </Box>
    </Layer>
  );
};

BusinessModal.propTypes = {};
BusinessModal.defaultProps = {};
