import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useFetchHairStyles } from './redux/hooks';
import { Box, Select, Button, Text, Image } from 'grommet';
import { FormClose } from 'grommet-icons';
import PhotoService from "../../services/photo.service";

export default function HairStyleSelect(props) {
  
  const { currentValue } = props;
  const { hairStyles } = useFetchHairStyles();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(()=>{
    setOptions(hairStyles||[]);
  }, [hairStyles]);

  useEffect(()=>{
    const nextSelected = options.filter(a => !!currentValue.find(b=>a._id === b));
    setSelected(nextSelected);
  }, [options]);


  const onRemoveHairStyle = hairStyle => {
    const nextSelected = selected.filter(selectedHairStyle => selectedHairStyle._id !== hairStyle._id);
    setSelected(nextSelected);
    props.onChange({target:{name:props.name, value:nextSelected}});
  };

  const renderHairStyle = hairStyle => (
    hairStyle && <Button
      key={`hairStyle_tag_${hairStyle._id}`}
      href="#"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onRemoveHairStyle(hairStyle);
      }}
      onFocus={event => event.stopPropagation()}
    >
      <Box
        align="center"
        direction="row"
        gap="xsmall"
        pad={{ vertical: "xsmall", horizontal: "small" }}
        margin="xsmall"
        background="brand"
        round="large"
      >
        <Text size="small" color="white">
          {hairStyle.name}
        </Text>
        <Box background="white" round="full" margin={{ left: "xsmall" }}>
          <FormClose
            color="brand"
            size="small"
            style={{ width: "12px", height: "12px" }}
          />
        </Box>
      </Box>
    </Button>
  );

  function handleChange({option:nextSelected})
  {
    const alreadySelected = selected.find(sel => sel._id === nextSelected._id);
    if (alreadySelected)
    {
      onRemoveHairStyle(nextSelected);
    }
    else
    {
      setSelected([...selected, nextSelected]);
      props.onChange({target:{name:props.name, value:[...selected, nextSelected]}});
    }
  }
 
  const renderOption = (option, state) => {
    const isSelected = selected.find(sel => sel._id === option._id);
    return <Box pad="small"  gap="small"
                background={state.active ? "active" : (isSelected ? "brand" : undefined)} 
                direction="row">
      <Box alignSelf="center">
        <Image height="30px" width="30px"
                  fit="cover"
                  src={PhotoService.getURL(option.photo) || require('../../images/nophoto.jpg')}
                  className="hair-styles-select-image" />
      </Box>
      <Text alignSelf="center">{option.name}</Text>
    </Box>
  };

  return (
      <Select
        plain
        size="medium"
        placeholder="Select hairStyles"
        value={selected}
        valueLabel={
            <Box wrap direction="row" >
              {selected && selected.length ? (
                selected.map(hairStyle => renderHairStyle(hairStyle))
              ) : (
                <Text color="dark-6">
                  Select Hair Styles
                </Text>
              )}
            </Box>
          }
        closeOnChange={false}
        multiple
        dropHeight="medium"
        options={options || []}
        onChange={handleChange}
        labelKey="name"
        valueKey={{ key: "_id", reduce: true }}
        onClose={() => setOptions(hairStyles)}
        searchPlaceholder="Search"
        onSearch={text => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          // Create the regular expression with modified value which
          // handles escaping special characters. Without escaping special
          // characters, errors will appear in the console
          const exp = new RegExp("^("+escapedText+")", "i");
          setOptions(hairStyles.filter(o => exp.test(o.name)));
        }}
      >{renderOption}</Select>
  );
};

HairStyleSelect.propTypes = {};
HairStyleSelect.defaultProps = {};
