import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, DataTable, Button, Heading } from 'grommet';
import { Trash, Lock } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { AddUser } from './';
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { useSetCurrentUser } from './redux/hooks';
import { presentAlertPrompt } from '../common/Alert';

export default function SettingsUsers(props) {
  
  const [ list, setList ] = useState([]);
  const { currentUser } = useSetCurrentUser(null);
  
  const columns = [
  {
    property: 'username',
    header: "Username",
    primary:true,
  },
  {
    property: 'email',
    header: "Email"
  },
  {
    property: 'role',
    header: "Role"
  },
  {
    property: "actions",
    render: datum => (
       (datum.id === currentUser.id || (currentUser.role === 'manager' && datum.role==='manager')) ? 
        ""
        :
        <Box direction="row" justify="between" gap="medium">
          <Button icon={<Lock size="small" />} size="small" label="Reset Pwd" 
                    primary color="status-warning" 
                    onClick={() => handleResetPassword(datum.id)}
                    />
          <Button icon={<Trash size="small" />} size="small" label="Remove" 
                    primary color="status-critical" 
                    onClick={() => handleRemoveUser(datum.id)}
                    />
        </Box>
       )
  },
  ];

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    UserService.getUsers().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }
  
  const handleAddUser = (user) => 
  {
    AuthService.register(user).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Success");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleRemoveUser = (userId) => {
    presentAlertPrompt({title:"Are you sure?", 
                message:"Do you really want to delete this user?", 
                onOK:()=>{removeUser(userId)}, 
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeUser = (userId) => {
    UserService.deleteUser(userId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("User successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleResetPassword = (userId) => {
    presentAlertPrompt({title:"Reset Password?", 
        message:"This will send a new password to this user?", 
        onOK:()=>{resetPassword(userId)}, 
        buttonOKText:"Reset",
        buttonOKColor:"status-critical"
    });
  }

  const resetPassword = (userId) => {
    AuthService.resetPassword(userId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Password reset.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }


  return (
      <Box gap="medium" justify="start">
        <Heading alignSelf="start" level="2">Front-Desk Users</Heading>
        <Header align="center" 
                direction="row" 
                justify="between" 
                gap="medium" 
                fill="horizontal">
          <AddUser onSubmit={handleAddUser} />
        </Header>
        <Box align="center" fill="horizontal" >
        <DataTable
          columns={columns}
          data={list}
          primaryKey="id"
        />
        </Box>
      </Box>
  );
};

SettingsUsers.propTypes = {};
SettingsUsers.defaultProps = {};
