import React, { useContext } from 'react';
import { Grid, Box, ResponsiveContext, Text, Footer, Image} from 'grommet';
import { LoginComponent } from './';

const areas = {
        small: [
          [ 'login' ]
        ],
        medium: [
          [ 'artwork', 'login' ]
        ]
      };

const columns = {
        small: ['flex'],
        medium: ['auto', 'large'],
        large: ['auto', 'large']
      };

export default function WelcomePage({children}) {

  var contextSize = useContext(ResponsiveContext);
  if (contextSize !== "small") contextSize = "medium";

  return (
      <Grid fill
        areas={areas[contextSize]}
        columns={columns[contextSize]}
        rows={['flex']}
        gap='none'
      >
        <Box gridArea="artwork" className="artwork" background={{ "color": "white"}}
                                                    pad="medium" >
          <Image
            fill="horizontal"
            src="/images/login-logo.png"
            className="logo" alignSelf="center"
          />
        </Box>
        <Box gridArea="login"  style={{"minWidth":"300px", margin:"auto"}}>
          {children}
          <Footer pad='medium' style={{'position':'absolute', 'bottom':0}}>
            <Text textAlign="center" size="small">@ 2021 Copyright MyAfroHair</Text>
          </Footer>
        </Box>
      </Grid>
  );
}
