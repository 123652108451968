import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HOME_SET_CURRENT_USER,
} from './constants';

export function setCurrentUser(user) {
  return {
    type: HOME_SET_CURRENT_USER,
    user:user
  };
}

export function useSetCurrentUser() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.home.currentUser);
  const boundAction = useCallback((...params) => dispatch(setCurrentUser(...params)), [dispatch]);
  return { currentUser, setCurrentUser: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_SET_CURRENT_USER:
      return {
        ...state,
        user:action.user
      };

    default:
      return state;
  }
}
