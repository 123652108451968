import React, { useEffect, useState } from 'react';
import { Grid, Box, Text, Button } from 'grommet';
import { NavBar } from './';
import UserService from "../../services/user.service";
import { API_URL } from "../../services/api";
// import PropTypes from 'prop-types';
import {} from './redux/hooks';
import history from '../../common/history';
import { useSetCurrentUser, useFetchHairStyles } from './redux/hooks';

export default function DashboardPage({ children }) {
  const { fetchHairStyles } = useFetchHairStyles();
  const [sidebar, setSidebar] = useState(true);
  const {currentUser, setCurrentUser} = useSetCurrentUser(null);

  useEffect(() => {
    UserService.getUser().then(
        response => {
          setCurrentUser(response.data);
          fetchHairStyles();
        },
        error => {
          history.push("/sign-in");
          window.location.reload();
        }
    );

  }, [fetchHairStyles, setCurrentUser]);

  return (
    currentUser &&
    <Grid
        fill
        rows={["auto", "flex"]}
        columns={["auto", "flex"]}
        areas={[
          { name: "header", start: [0, 0], end: [1, 0] },
          { name: "sidebar", start: [0, 1], end: [0, 1] },
          { name: "main", start: [1, 1], end: [1, 1] }
        ]}
      >
        <Box
          gridArea="header"
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: "medium", vertical: "small" }}
          background="brand"
        >
          <Button onClick={() => setSidebar(!sidebar)}>
            <Text size="large">MyAfroHair Back Office</Text>
            <Text size="small" margin="medium">{(API_URL === "http://localhost:4444/v1/") && "DEVELOPMENT"}
            </Text>
          </Button>
          <Text>{currentUser.username}</Text>
        </Box>
        {sidebar && <NavBar gridArea="sidebar" /> }
        <Box gridArea="main" justify="start" align="start" overflow="auto" pad="large" flex="grow">
          {children}
        </Box>
      </Grid>
  );
}

DashboardPage.propTypes = {};
DashboardPage.defaultProps = {};
