export function capitalize(string) 
{
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1);
  return "";
}

export function onDatatableSearch (search, wholeList, setStateFunc) {
    let nextData;
    if (search) {
      // The function below escapes regular expression special characters:
      // [ \ ^ $ . | ? * + ( )
      const escapedText = text => {
        text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
        return new RegExp(text, "i");
      };
      const expressions = Object.keys(search).map(property => ({
        property,
        // Create the regular expression with modified value which handles
        // escaping special characters. Without escaping special characters,
        // errors will appear in the console
        exp: new RegExp(escapedText(search[property]), "i")
      }));

      nextData = wholeList.filter(        
        d => !expressions.some(e => !e.exp.test(d[e.property]))
      );
    } else {
      nextData = wholeList;
    }
    setStateFunc(nextData);
  };

export const STATES_OPTIONS_LIST = 
  [
    { value:"AL", label:"Alabama", timezoneOffset:-6 },
    { value:"AK", label:"Alaska", timezoneOffset:-9 },
    { value:"AZ", label:"Arizona", timezoneOffset:-7 },
    { value:"AR", label:"Arkansas", timezoneOffset:-6 },
    { value:"CA", label:"California", timezoneOffset:-8 },
    { value:"CO", label:"Colorado", timezoneOffset:-7 },
    { value:"CT", label:"Connecticut", timezoneOffset:-5 },
    { value:"DE", label:"Delaware", timezoneOffset:-5 },
    { value:"DC", label:"District Of Columbia", timezoneOffset:-5 },
    { value:"FL", label:"Florida", timezoneOffset:-5 },
    { value:"GA", label:"Georgia", timezoneOffset:-5 },
    { value:"HI", label:"Hawaii", timezoneOffset:-10 },
    { value:"ID", label:"Idaho", timezoneOffset:-7 },
    { value:"IL", label:"Illinois", timezoneOffset:-6 },
    { value:"IN", label:"Indiana", timezoneOffset:-5 },
    { value:"IA", label:"Iowa", timezoneOffset:-6 },
    { value:"KS", label:"Kansas", timezoneOffset:-6 },
    { value:"KY", label:"Kentucky", timezoneOffset:-6 },
    { value:"LA", label:"Louisiana", timezoneOffset:-6 },
    { value:"ME", label:"Maine", timezoneOffset:-5 },
    { value:"MD", label:"Maryland", timezoneOffset:-5 },
    { value:"MA", label:"Massachusetts", timezoneOffset:-5 },
    { value:"MI", label:"Michigan", timezoneOffset:-5 },
    { value:"MN", label:"Minnesota", timezoneOffset:-6 },
    { value:"MS", label:"Mississippi", timezoneOffset:-6 },
    { value:"MO", label:"Missouri", timezoneOffset:-6 },
    { value:"MT", label:"Montana", timezoneOffset:-7 },
    { value:"NE", label:"Nebraska", timezoneOffset:-6 },
    { value:"NV", label:"Nevada", timezoneOffset:-8 },
    { value:"NH", label:"New Hampshire", timezoneOffset:-5 },
    { value:"NJ", label:"New Jersey", timezoneOffset:-5 },
    { value:"NM", label:"New Mexico", timezoneOffset:-7 },
    { value:"NY", label:"New York", timezoneOffset:-5 },
    { value:"NC", label:"North Carolina", timezoneOffset:-5 },
    { value:"ND", label:"North Dakota", timezoneOffset:-6 },
    { value:"OH", label:"Ohio", timezoneOffset:-5 },
    { value:"OK", label:"Oklahoma", timezoneOffset:-6 },
    { value:"OR", label:"Oregon", timezoneOffset:-8 },
    { value:"PA", label:"Pennsylvania", timezoneOffset:-5 },
    { value:"RI", label:"Rhode Island", timezoneOffset:-5 },
    { value:"SC", label:"South Carolina", timezoneOffset:-5 },
    { value:"SD", label:"South Dakota", timezoneOffset:-6 },
    { value:"TN", label:"Tennessee", timezoneOffset:-6 },
    { value:"TX", label:"Texas", timezoneOffset:-6 },
    { value:"UT", label:"Utah", timezoneOffset:-7 },
    { value:"VT", label:"Vermont", timezoneOffset:-5 },
    { value:"VA", label:"Virginia", timezoneOffset:-5 },
    { value:"WA", label:"Washington", timezoneOffset:-8 },
    { value:"WV", label:"West Virginia", timezoneOffset:-5 },
    { value:"WI", label:"Wisconsin", timezoneOffset:-6 },
    { value:"WY", label:"Wyoming", timezoneOffset:-7 }
  ];

export function roundPrice (value) {
  return Math.round(value*100)/100;
}

export function getDuration (value) {
  if (value<60) return Math.floor(value) + "m";
  var hours = Math.floor(value/60);
  var minutes = Math.floor(value - hours * 60);
  return hours + "h" + (minutes>0 ? minutes : "");
}
