import React from 'react';
// import PropTypes from 'prop-types';
import Moment from 'react-moment';

export default function BookingDates(props) {

  const { checkinDate, checkoutDate, placeholder, year } = props;

  const format = (year) ? "ll" : "MMM D";

  return (
    (checkinDate && checkoutDate) ?  
      <span><Moment format={format}>{checkinDate}</Moment> - <Moment format={format}>{checkoutDate}</Moment></span>
        : (checkinDate ?
          <span><Moment format={format}>{checkinDate}</Moment> - ??</span>
            : (placeholder) ? 
              placeholder 
                : "-")
  );
};

BookingDates.propTypes = {};
BookingDates.defaultProps = {};
