import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DASHBOARD_SET_CURRENT_USER,
} from './constants';

export function setCurrentUser(currentUser) {
  return {
    type: DASHBOARD_SET_CURRENT_USER,
    currentUser
  };
}

export function useSetCurrentUser() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.dashboard.currentUser);
  const boundAction = useCallback((...params) => dispatch(setCurrentUser(...params)), [dispatch]);
  return { currentUser, setCurrentUser: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_SET_CURRENT_USER:
      return {
        ...state,
        currentUser:action.currentUser
      };

    default:
      return state;
  }
}
