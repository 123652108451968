import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'hair-style/';

class HairStyleService {

  getHairStyles() {
    return axios.get(ENDPOINT_URL + "list", { headers: authHeader() });
  }

  getHairStyle(hairStyleId) {
    return axios.get(ENDPOINT_URL + hairStyleId, { headers: authHeader() });
  }

  addHairStyle(hairStyle) {
    return axios.post(ENDPOINT_URL, hairStyle, { headers: authHeader() });
  }

  deleteHairStyle(hairStyleId) {
    return axios.delete(ENDPOINT_URL + hairStyleId, { headers: authHeader() });
  }

  //hairStyleIds : [<string>, <string>, ...]
  deleteHairStyles(hairStyleIds) {
    return axios.post(ENDPOINT_URL + 'deletemany', hairStyleIds, { headers: authHeader() });
  }

  updateHairStyle(hairStyle) {
    return axios.patch(ENDPOINT_URL, hairStyle, { headers: authHeader() } );
  }

  updateOrder(hairStyleIds) {
    return axios.patch(ENDPOINT_URL + 'order', hairStyleIds, { headers: authHeader() });
  }

  uploadPhoto(hairStyleId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + "uploadPhoto/" + hairStyleId,
                      formData,
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }
}

export default new HairStyleService();
