import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'business/';

class BusinessService {
  getBusinesses() {
    return axios.get(ENDPOINT_URL + 'list', { headers: authHeader() });
  }

  getBusiness(businessId) {
    return axios.get(ENDPOINT_URL + businessId, { headers: authHeader() });
  }

  addBusiness(business) {
    return axios.post(ENDPOINT_URL, business, { headers: authHeader() });
  }

  updateBusiness(business) {
    return axios.patch(ENDPOINT_URL, business, { headers: authHeader() });
  }

  deleteBusiness(businessId) {
    return axios.delete(ENDPOINT_URL + businessId, { headers: authHeader() });
  }

  uploadCover(businessId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + businessId + "/uploadCover",
                      formData,
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }

}



export default new BusinessService();
