import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {} from './redux/hooks';
import { Box, Header, Heading, Button, DataTable } from 'grommet';
import { Add, Edit, Trash } from 'grommet-icons';
import BusinessService from "../../services/business.service";
import { onDatatableSearch } from "../../common/utils";
import { BusinessModal } from "./";
import { presentAlertPrompt } from '../common/Alert';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import { presentLoading, dismissLoading } from '../common/Loading';

  const fetchBusinesses = (setBusinessList, setAllBusinessList) =>
  {
    presentLoading();
    BusinessService.getBusinesses().then(
      (response) => {
        var list = response.data.map(business => ({
          id:business._id,
          slug:business.slug,
          name:business.name,
          city:business.city,
          state:business.state,
          active:business.active
        }));
        dismissLoading();
        setBusinessList(list);
        setAllBusinessList(list);
      },
      (error) => {
        dismissLoading();
        setBusinessList([]);
        setAllBusinessList([]);
      }
    );
  }

export default function BusinessPage() {
  const [ businessList, setBusinessList ] = useState([]);
  const [ allBusinessList, setAllBusinessList ] = useState([]);
  const [ businessModal, setBusinessModal ] = useState(false);

  const columns = [
  {
    property: 'name',
    header: "Name",
    search:true,
  },
  {
    property: 'slug',
    header: 'Slug',
    align: 'center',
    search:true
  },
  {
    property: 'city',
    header: 'City',
    align: 'center',
    search:true
  },
  {
    property: 'state',
    header: 'State',
    align: 'center',
    search:true
  },
  {
    property: 'actions',
    header: 'Actions',
    align: 'center',
    render: datum => (<Box direction="row" gap="medium">
          <Button hoverIndicator="accent-4" icon={<Edit size="small" />} size="small"
                    primary color="status-warning"
                    onClick={() => handleBusinessDetails(datum.id)}
                    />
          <Button hoverIndicator="dark-1" icon={<Trash size="small" />} size="small"
                    primary color="status-critical"
                    onClick={() => handleRemoveBooking(datum.id)}
                    />
        </Box>)
  }];

  useEffect(() => {
      fetchBusinesses(setBusinessList, setAllBusinessList);
  }, []);

  const handleAddBusiness = () => {
    setBusinessModal(true);
  }

  const handleBusinessDetails = (businessId) => {
    window.location.href = '/business/' + businessId;
  }

  const handleBusinessModalClose = (refresh) =>
  {
    if (refresh)
      fetchBusinesses(setBusinessList, setAllBusinessList);

    setBusinessModal(false);
  }

  const handleRemoveBooking = (businessId) => {
    function removeBusiness() {
      BusinessService.deleteBusiness(businessId).then(
        (response) => {
          presentToastSuccess("Reservation successfully removed.");
          fetchBusinesses(setBusinessList, setAllBusinessList);
        },
        (error) => {
          presentToastErrorContent(error);
          fetchBusinesses(setBusinessList, setAllBusinessList);
        }
      );
    };

    presentAlertPrompt({title:"Are you sure?",
                        message:"This will delete the reservation, but the traveler will stay in our database.",
                        onOK:removeBusiness,
                        buttonOKText:"Delete",
                        buttonOKColor:"status-critical"
    });
  }



  return (
     <Box align="center" justify="start" fill pad="medium">
      {
        businessModal &&
          <BusinessModal onClose={handleBusinessModalClose}/>
      }
      <Header align="center" direction="row" justify="between" gap="medium" fill="horizontal">
        <Heading level="2">Hair Stylists</Heading>
        <Button icon={<Add />} label="Add" primary onClick={handleAddBusiness}/>
      </Header>
      <Box align="center" fill="horizontal" >
        <DataTable
          columns={columns}
          data={businessList}
          primaryKey="id"
          onClickRow={event => {
            //console.log(event.target.localName);
            // if (event.target.localName!=='button' && event.target.localName!=='svg')
            //   handleBusinessDetails(event.datum.id);
            }
          }
          onSearch={(search)=>onDatatableSearch(search, allBusinessList, setBusinessList)}
        />
      </Box>
    </Box>
  );
};

BusinessPage.propTypes = {};
BusinessPage.defaultProps = {};
