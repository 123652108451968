import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, Button, Heading, Text, Image } from 'grommet';
import { Trash, Edit, Add, Menu } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { AddService } from './';
import { getDuration, roundPrice } from '../../common/utils'
import MenuService from "../../services/menu.service";
import { presentAlertPrompt } from '../common/Alert';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PhotoService from "../../services/photo.service";

export default function ServicesMenu(props) {

  const { businessId } = props;
  const [ list, setList ] = useState([]);
  const [ serviceId, setServiceId ] = useState(0);
  const [ openEdit, setOpenEdit ] = useState(false);

  useEffect(() => {
    onRefresh();
  }, [businessId]);


const DragHandle = sortableHandle(() => <Menu />);

const SortableItem = sortableElement(({value}) => (
  <Box direction="row" gap="medium" justify="between">
    <Box alignContent="center" justify="center" className="service-image-column">
      <DragHandle />
    </Box>
    <Box direction="row" gap="medium" width="400px" className="service-image-column" align="start">
        <Box height="70px" width="70px">
          <Image height="70px" width="70px"
                  fit="cover"
                  src={PhotoService.getURL(value.photo) || require('../../images/nophoto.jpg')}
                  className="service-image-image" onClick={() => handleEdit(value._id)} />
        </Box>
        <Box gap="small" width="330px" className="service-image-column">
          <Text weight="bold">{value.name}</Text>
          <Text truncate color="dark-6" style={{fontStyle:'italic'}}>{value.description}</Text>
        </Box>
    </Box>
    <Box direction="row" gap="medium" width="70px" className="service-image-column" align="center">
        <Box className="service-image-column" width="100px" justify="center"> 
          <Text weight="bold" >{getDuration(value.duration)}</Text>
        </Box>
    </Box>
    <Box direction="row" gap="medium" width="70px" className="service-image-column" align="center">
        <Box className="service-image-column" width="100px" justify="center"> 
          <Text weight="bold" >${roundPrice(value.price)}</Text>
        </Box>
    </Box>
    <Box direction="row" gap="medium" className="service-image-column">
      <Button icon={<Edit size="small" />} size="small" label="Edit" alignSelf="center"
                primary color="status-warning"
                onClick={() => handleEdit(value._id)}
                />
      <Button icon={<Trash size="small" />} size="small" label="Remove" alignSelf="center"
                primary color="status-critical"
                onClick={() => handleRemoveService(value._id)}
                />
    </Box>
  </Box>
));

const SortableContainer = sortableContainer(({children}) => {
  return <Box gap="small" align="start" fill="horizontal">{children}</Box>;
});

  const onRefresh = () => {
    MenuService.getServices(businessId).then(
      (response) => {
        setList(response.data || []);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEditModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEdit(false);
  }

  const handleRemoveService = (serviceId) => {
    presentAlertPrompt({title:"Are you sure?",
                message:"Do you really want to delete this service?",
                onOK:()=>{removeService(serviceId)},
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeService = (servicesId) => {
    MenuService.deleteService(businessId, servicesId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Service successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEdit = (servicesId) => {
    setServiceId(servicesId);
    setOpenEdit(true);
  }

  const handleAdd = () => {
    setServiceId(0);
    setOpenEdit(true);
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newSortedList = arrayMove(list, oldIndex, newIndex);
    const listIds = newSortedList.map(x=>x._id);
    MenuService.updateOrder(businessId, listIds).then(
      (response) => {
          setList(newSortedList);
          onRefresh();
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );

  };

  return (
      <Box gap="medium" justify="start">
        <Header align="center"
                direction="row"
                justify="end"
                gap="medium"
                fill="horizontal">
          <Button primary icon={<Add size="small" />} size="small" label="Add" onClick={handleAdd} />
          { openEdit &&
            <AddService serviceId={serviceId} onClose={handleEditModalClose} businessId={businessId}/>
          }
        </Header>
        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
          {list.map((value, index) => (
            <SortableItem key={`style-${value._id}`} index={index} value={value} />
          ))}
        </SortableContainer>
      </Box>
  );
};

ServicesMenu.propTypes = {};
ServicesMenu.defaultProps = {};
