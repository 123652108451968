import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, Text, TextArea, FormField } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import MenuService from "../../services/menu.service";
import PhotoService from "../../services/photo.service";
import { PhotoUploader } from './';

const defaultValues = {
  _id:0,
  name:"",
  duration:0,
  price:0,
  description:"",
  photo:"",
}

export default function AddService(props) {
  const { serviceId, onClose, businessId } = props;
  const [service, setService] = useState(defaultValues);
  const [photo, setPhoto] = useState(undefined);

  function handleInputChange({target:{name, value}}) {
    var values = {...service};
    values[name] = value;
    setService(values);
  }

  const handleAdd = () =>
  {
    if (serviceId === 0)
    {
      MenuService.addService(businessId, service).then(
        async (response) => {
            const service = response.data && response.data.service;
            if (service)
              await uploadPhoto(service._id);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      MenuService.updateService(businessId, service).then(
        async (response) => {
            await uploadPhoto(serviceId);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  }

  const uploadPhoto = async (serviceId) =>
  {
    if (!photo) return;
    await MenuService.uploadPhoto(businessId, serviceId, photo).then(
      (response) => {
        if (response.data.success)
        {
          //presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  useEffect(() => {
    if (serviceId !== 0)
    {
      presentLoading();
      MenuService.getService(businessId, serviceId).then(
        (response) => {
          dismissLoading();
          const service = response.data;
          if (service._id)
          {
            setService(service);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      setService(defaultValues);
    }
  }, [businessId, serviceId]);

  return (
        <Layer position="center" onClickOutside={()=>onClose(false)} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large">
            <Heading level={3} margin="none">
              Add Service
            </Heading>
            <Box direction="row"  gap="medium">
              <Box gap="medium">
                <FormField label="Name" width="medium">
                  <TextInput name="name" value={service.name} onChange={handleInputChange} />
                </FormField>
                <Text>Description</Text>
                <TextArea style={{height:150}} name="description" value={service.description} onChange={handleInputChange} />
                <Box direction="row" gap="small">
                  <FormField label="Duration (in minutes)" >
                    <TextInput name="duration" value={service.duration} onChange={handleInputChange} />
                  </FormField>
                  <FormField label="Price" >
                    <TextInput name="price" value={service.price} onChange={handleInputChange} />
                  </FormField>
                </Box>
              </Box>
              <Box alignSelf="center">
                <PhotoUploader height={240} width={200} 
                             label="Photo (jpg, 400x480, Quality 80)" 
                             onChange={setPhoto} photo={PhotoService.getURL(service.photo) || ""} />
              </Box>
            </Box>
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={()=>onClose(false)} color="dark-3" />
              <Button label={serviceId===0?"Add":"Update"} onClick={handleAdd} primary color="status-ok" />
            </Box>
          </Box>
        </Layer>
  );
};

AddService.propTypes = {};
AddService.defaultProps = {};
