import React, { useEffect, useState, useContext, Suspense } from 'react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Grid, ResponsiveContext, Select, Header, TextArea, Box, 
          Heading, Button, FormField, TextInput, CheckBox, Text,
          Tabs, Tab } from 'grommet';
import BusinessService from "../../services/business.service";
import PhotoService from "../../services/photo.service";
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import { presentLoading, dismissLoading } from '../common/Loading';
import { PhoneInput, HourInput } from '../common';
import { PhotoUploader, HairStyleSelect, ServicesMenu} from './';
import { STATES_OPTIONS_LIST } from "../../common/utils";

const Map = React.lazy(() => import('./Map'));

const defaultValues = {
  _id:0,
  slug:"",
  name:"",
  email:"",
  isSalon:true,
  isRemote:false,
  hours:{sun:[["10:00","13:00"]], mon:[["09:00","18:00"]], tue:[["09:00","18:00"]], wed:[["09:00","18:00"]], thu:[["09:00","21:00"]], fri:[["09:00","16:00"]], sat:[["09:00","18:00"]]},
  description:"",
  phone:false,
  cover:"",
  photos:[],
  hairStyles:[],
  active:false
}

const defaultMapValues = {
  address:"",
  city:"",
  state:"",
  zipCode:"",
  country:"USA",
  latitude:0,
  longitude:0,
  timezone:""
}

const areas = {
        small: [
          [ 'info', 'info' ] ,
          [ 'cover', 'cover' ] ,
          [ 'map', 'map' ],
          [ 'address', 'address' ],
          [ 'description', 'description' ],
          [ 'hairstyles', 'hairstyles', 
          [ 'footer', 'footer'] ]
        ],
        medium: [
          [ 'info', 'cover' ] ,
          [ 'address', 'map' ] ,
          [ 'description', 'hairstyles' ] ,
          [ 'footer', 'footer']
        ]
      };

const rows = {
        small: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        medium: ['auto', 'auto', 'auto', 'auto']
      };

export default function BusinessDetails(props) {

  var contextSize = useContext(ResponsiveContext);
  if (contextSize !== "small") contextSize = "medium";

  var { businessId } = useParams();
  const [ formValues, setFormValues ] = useState(defaultValues);
  const [ mapValues, setMapValues ] = useState(defaultMapValues);
  const [statesOptions, setStatesOptions] = useState(STATES_OPTIONS_LIST);

  const [index, setIndex] = useState();
  const onActive = nextIndex => setIndex(nextIndex);

  const refreshDetails = (businessId) => {
      presentLoading();
      BusinessService.getBusiness(businessId).then(
        (response) => {
          dismissLoading();
          const business = response.data;
          if (business._id)
          {
            let longitude = business.location && business.location.coordinates[0];
            let latitude = business.location && business.location.coordinates[1];
            setFormValues({...defaultValues, ...business});
            setMapValues({longitude, latitude, address:business.address,
                                               city:business.city,
                                               state:business.state,
                                               zipCode:business.zipCode,
                                               country:business.country, 
                                               timezone:business.timezone})
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
  }

  useEffect(() => {
    if (businessId && businessId !== "0")
    {
      refreshDetails(businessId);
    }
  }, [businessId]);

  function handleInputChange({target:{name, value}}) {
    var values = {...formValues};
    values[name] = value;
    setFormValues(values);
  }

  function handleMapInputChange({target:{name, value}}) {
    var values = {...mapValues};
    values[name] = value;
    setMapValues(values);
  }

  function handleCheckboxChange({target:{name, checked}}) {
    var values = {...formValues};
    values[name] = checked;
    setFormValues(values);
  }

  function handlePhoneInputChange(phone) {
    setFormValues({...formValues, phone});
  }

  const handleUpdate = () => 
  {
    if (formValues._id !== 0)
    {
      presentLoading();

      let hairStyles = [];
      if (formValues.hairStyles.length > 0)
      {
        hairStyles = formValues.hairStyles[0] && formValues.hairStyles[0]._id ? formValues.hairStyles.map(h=>h._id) : formValues.hairStyles;
      }

      BusinessService.updateBusiness({...formValues, hairStyles, ...mapValues}).then(
        (response) => {
          dismissLoading();
          if (response.data.ok)
          {
            if (response.data.nModified)
              presentToastSuccess("Hair stylist details updated");
            refreshDetails(businessId);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
  }

  const handleUploadCover = (file) =>
  {
    BusinessService.uploadCover(formValues._id, file).then(
      (response) => {
        if (response.data.success)
        {
          presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleBack = () =>
  {
    var index = window.location.href.lastIndexOf("/");
    window.location.href = window.location.href.substring(0,index);
  };

  const onMapChange = (result) => {
    console.log("onMapChange",result);
   setMapValues({...result });
  };

  const handleHoursChange = ({name, value}) => {
    var hours = {...formValues.hours};
    var arr = name.split(".");
    var day = arr[0];
    var indexOpenClose = arr[1] === "close" ? 1 : 0;

    if (value === "Closed") 
    {
      hours[day] = [];
    }
    else
    {
      if (formValues.hours[day].length === 0) hours[day] = [["09:00", "18:00"]];
      hours[day][0][indexOpenClose] = value;
    }

    setFormValues({...formValues, hours });
  };

  const getHourValue = (day, indexOpenClose) =>
  {
    return (formValues.hours && formValues.hours[day] && formValues.hours[day][0] && formValues.hours[day][0][indexOpenClose]) || "";
  }

  return (
    <Box align="center" justify="start" pad="medium" gap="medium" fill="horizontal">
      <Header align="center" direction="row" fill="horizontal" className="details-header">
        <Button label="Back" primary onClick={handleBack} className="back-button"/>
        <Heading level="2" className="heading">Hair Stylist details</Heading>
      </Header>

      <Tabs activeIndex={index} onActive={onActive}>
          <Tab title="Information">
            <Box margin="small" pad="large" justify="start" align="center" gap="medium">
              <CheckBox name="active" toggle
                  checked={formValues.active} 
                  onChange={handleCheckboxChange} 
                  label="Active"/>
              <Grid className="home-page" 
                  areas={areas[contextSize]}
                  columns={[["auto", "420px"], ["auto", "600px"]]}
                  rows={rows[contextSize]}
                  gap='medium'
                >
                <Box gridArea="info" pad="medium" gap="small" background="light-1" round>
                  <Box direction="row" gap="medium" justifyContent="around" >
                    <CheckBox name="isSalon" 
                              checked={formValues.isSalon} 
                              onChange={handleCheckboxChange} 
                              label="Salon"/>
                    <CheckBox name="isRemote" 
                              checked={formValues.isRemote} 
                              onChange={handleCheckboxChange} 
                              label="Remote"/>
                  </Box>
                  <FormField label="Name">
                      <TextInput name="name" value={formValues.name} onChange={handleInputChange} />
                  </FormField>
                  <FormField label="Email" >
                    <TextInput name="email" value={formValues.email} onChange={handleInputChange} placeholder="example@my.com"/>
                  </FormField>
                  <FormField label="Phone number" className="phone-field">
                    <PhoneInput  name="phone" value={formValues.phone || ""} onChange={handlePhoneInputChange} />
                  </FormField>
                </Box>
                <Box gridArea="cover" pad="medium" gap="medium" background="light-1" round  align="center">
                  <PhotoUploader label="Cover photo" onChange={handleUploadCover} photo={PhotoService.getURL(formValues.cover)}/>
                  <Text alignSelf="center" color="dark-1">Slug: <b>{formValues.slug}</b></Text>
                </Box>
                <Box gridArea="address" pad="medium" gap="medium" background="light-1" round align="center">
                  <FormField label="Address" fill="horizontal">
                    <TextInput name="address" value={mapValues.address} onChange={handleMapInputChange} />
                  </FormField>
                    <FormField label="City" fill="horizontal">
                      <TextInput name="city" value={mapValues.city} onChange={handleMapInputChange} />
                    </FormField>
                    <Box direction="row" gap="small">
                    <FormField label="State">
                        <Select
                            size="medium"
                            labelKey="label"
                            valueKey={{ key: "value", reduce: true }}
                            placeholder="Select State"
                            value={mapValues.state || ""}
                            options={statesOptions}
                            onChange={({ value: nextValue }) => setMapValues({...mapValues, state:nextValue})}
                            onClose={() => setStatesOptions(STATES_OPTIONS_LIST)}
                            onSearch={text => {
                              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                              const exp = new RegExp(escapedText, "i");
                              setStatesOptions(STATES_OPTIONS_LIST.filter(o => (exp.test(o.value) || exp.test(o.label))));
                            }}
                          />
                    </FormField>
                    <FormField label="Zip Code" >
                      <TextInput name="zipCode" value={mapValues.zipCode} onChange={handleMapInputChange} />
                    </FormField> 
                  </Box>
                  {mapValues.longitude && mapValues.latitude ? <Text>{mapValues.longitude},{mapValues.latitude}, {mapValues.timezone}</Text>
                  : <Text>Lng,Lat, timezone</Text>}
                </Box>
                <Suspense fallback="">
                  <Map gridArea='map' onChange={onMapChange} coords={{lat:mapValues.latitude, lng:mapValues.longitude}}/>
                </Suspense>
                
                <Box gridArea="description" pad="medium" gap="medium" background="light-1" round align="start">
                  <Text align="left">Description</Text>
                  <TextArea name="description" 
                              value={formValues.description} 
                              onChange={handleInputChange} 
                              plain={false}
                              className="textarea"
                              />
                  <Text align="left">Opening Hours</Text>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Sunday</Text>
                    <HourInput addClosed name="sun.open" value={getHourValue('sun', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="sun.close" value={getHourValue('sun', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Monday</Text>
                    <HourInput addClosed name="mon.open" value={getHourValue('mon', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="mon.close" value={getHourValue('mon', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Tuesday</Text>
                    <HourInput addClosed name="tue.open" value={getHourValue('tue', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="tue.close" value={getHourValue('tue', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Wednesday</Text>
                    <HourInput addClosed name="wed.open" value={getHourValue('wed', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="wed.close" value={getHourValue('wed', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Thursday</Text>
                    <HourInput addClosed name="thu.open" value={getHourValue('thu', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="thu.close" value={getHourValue('thu', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Friday</Text>
                    <HourInput addClosed name="fri.open" value={getHourValue('fri', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="fri.close" value={getHourValue('fri', 1)} onChange={handleHoursChange} />
                  </Box>
                  <Box width="100%" direction="row" justify="between" align="center">
                    <Text style={{width:80}}>Saturday</Text>
                    <HourInput addClosed name="sat.open" value={getHourValue('sat', 0)} onChange={handleHoursChange} />
                    <Text>-</Text>
                    <HourInput addClosed name="sat.close" value={getHourValue('sat', 1)} onChange={handleHoursChange} />
                  </Box>                                                                                       
                </Box>  
                <Box gridArea="hairstyles" pad="medium" gap="medium" background="light-1" round align="center">
                  <Text align="left">Hair Styles</Text>
                    <HairStyleSelect name="hairStyles" 
                                      currentValue={formValues.hairStyles}
                                      onChange={handleInputChange} 
                                      />
                </Box>  
                <Box gridArea="footer" pad="medium" gap="medium" align="center">
                  <Button label="Save" onClick={handleUpdate} primary alignSelf="center" />
                </Box>  
              </Grid>
            </Box>
          </Tab>
          <Tab title="Services Menu">
            <Box margin="small" pad="large">
              <ServicesMenu businessId={formValues._id} />
            </Box>
          </Tab>
      </Tabs>
    </Box>
  );
};

BusinessDetails.propTypes = {};
BusinessDetails.defaultProps = {};
