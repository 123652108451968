// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  DashboardPage,
  BusinessPage,
  SettingsUsers,
  SettingsHairStyles,
  BusinessDetails,
} from './';

export default {
  path: '',
  component: DashboardPage,
  childRoutes: [
    { path: '', component: BusinessPage, isIndex: true },
    { path: 'business', component: BusinessPage },
    { path: 'settings', component: SettingsHairStyles },
    { path: 'settings/users', component: SettingsUsers },
    { path: '/settings/hair-styles', component: SettingsHairStyles },
    { path: '/business/:businessId', component: BusinessDetails },
  ],
};
