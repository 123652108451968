import React from 'react';
import { Layer, Box, Text } from 'grommet';
import { StatusGood, StatusInfo, StatusWarning, StatusCritical } from 'grommet-icons';

const TYPE_SUCCESS = "status-ok"; 
const TYPE_INFO = "brand"; 
const TYPE_WARNING = "status-warning"; 
const TYPE_ERROR = "status-critical"; 

class Toast extends React.Component{

    constructor(){
        super();
        this.state = {open:false, message: '', type:TYPE_INFO};
        window.toast = this;
    }

    present(message, type){
      this.setState({...this.state, open:true, message:message||'', type});
      setTimeout(()=>{this.setState({...this.state, open:false});}, 3000);
    }

    render(){
      return (
        this.state.open && (
            <Layer
              position="bottom"
              modal={false}
              margin={{ vertical: "medium", horizontal: "small" }}
              responsive={false}
              plain
            >
              <Box
                align="center"
                direction="row"
                gap="small"
                justify="between"
                round="medium"
                elevation="medium"
                pad={{ vertical: "xsmall", horizontal: "small" }}
                background={this.state.type}
              >
                <Box align="center" direction="row" gap="xsmall">
                  {(this.state.type === TYPE_SUCCESS) && <StatusGood color="white"/>}
                  {(this.state.type === TYPE_INFO) && <StatusInfo color="white"/>}
                  {(this.state.type === TYPE_WARNING) && <StatusWarning color="white"/>}
                  {(this.state.type === TYPE_ERROR) && <StatusCritical color="white"/>}
                  <Text color="white">{this.state.message}</Text>
                </Box>
              </Box>
            </Layer>
          )
      )
    }
}

export default Toast;
export function presentToastInfo(message) {window.toast.present(message, TYPE_INFO);}
export function presentToastWarning(message) {window.toast.present(message, TYPE_WARNING);}
export function presentToastError(message) {window.toast.present(message, TYPE_ERROR);}
export function presentToastSuccess(message) {window.toast.present(message, TYPE_SUCCESS);}
export function presentToastErrorContent(error) {
  var _content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
  if (error.response && error.response.status === 404 ) _content = "API Endpoint not found";
  presentToastError(_content);
}