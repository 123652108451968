import React from 'react';
import { Grommet } from 'grommet';
import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { Toast, Alert, Loading }  from '../common/'


const theme = deepMerge(grommet, {
  "name": "my theme",
  "rounding": 4,
  "spacing": 20,
  "defaultMode": "light",
  "global": {
    "colors": {
      "brand": {
        "dark": "#ff9963",
        "light": "#fe8963"
      },
      "background": {
        "dark": "#111111",
        "light": "#FFFFFF"
      },
      "background-back": {
        "dark": "#111111",
        "light": "#EEEEEE"
      },
      "background-front": {
        "dark": "#222222",
        "light": "#FFFFFF"
      },
      "background-contrast": {
        "dark": "#FFFFFF11",
        "light": "#11111111"
      },
      "text": {
        "dark": "#EEEEEE",
        "light": "#333333"
      },
      "text-strong": {
        "dark": "#FFFFFF",
        "light": "#000000"
      },
      "text-weak": {
        "dark": "#CCCCCC",
        "light": "#444444"
      },
      "text-xweak": {
        "dark": "#999999",
        "light": "#666666"
      },
      "border": {
        "dark": "#444444",
        "light": "#CCCCCC"
      },
      "control": "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "white",//"text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
      'toggle-bg': '#757575',
      'toggle-knob': 'white',
      'toggle-accent': 'status-ok',
    },
    "font": {
      "family": "Helvetica",
      "size": "15px",
      "height": "20px",
      "maxWidth": "300px"
    },
    "active": {
      "background": "active-background",
      "color": "active-text"
    },
    "hover": {
      "background": "active-background",
      "color": "active-text"
    },
    "selected": {
      "background": "selected-background",
      "color": "selected-text"
    },
    "borderSize": {
      "xsmall": "1px",
      "small": "2px",
      "medium": "3px",
      "large": "10px",
      "xlarge": "20px"
    },
    "breakpoints": {
      "small": {
        "value": 640,
        "borderSize": {
          "xsmall": "1px",
          "small": "2px",
          "medium": "3px",
          "large": "5px",
          "xlarge": "10px"
        },
        "edgeSize": {
          "none": "0px",
          "hair": "1px",
          "xxsmall": "2px",
          "xsmall": "3px",
          "small": "5px",
          "medium": "10px",
          "large": "20px",
          "xlarge": "40px"
        },
        "size": {
          "xxsmall": "20px",
          "xsmall": "40px",
          "small": "80px",
          "medium": "160px",
          "large": "320px",
          "xlarge": "640px",
          "full": "100%"
        }
      },
      "medium": {
        "value": 1280
      },
      "large": {}
    },
    "edgeSize": {
      "none": "0px",
      "hair": "1px",
      "xxsmall": "3px",
      "xsmall": "5px",
      "small": "10px",
      "medium": "20px",
      "large": "40px",
      "xlarge": "80px",
      "responsiveBreakpoint": "small"
    },
    "input": {
      "padding": "10px",
      "weight": 600
    },
    "spacing": "20px",
    "size": {
      "xxsmall": "40px",
      "xsmall": "80px",
      "small": "160px",
      "medium": "320px",
      "large": "640px",
      "xlarge": "960px",
      "xxlarge": "1280px",
      "full": "100%"
    },
    "control": {
      "border": {
        "radius": "4px"
      }
    },
    "drop": {
      "border": {
        "radius": "4px"
      }
    }
  },
  "chart": {},
  "diagram": {
    "line": {}
  },
  "meter": {},
  "scale": 0.2,
  "button": {
    "border": {
      "width": "2px",
      "radius": "15px"
    },
    "padding": {
      "vertical": "3px",
      "horizontal": "18px"
    }
  },
  "calendar": {
    "small": {
      "fontSize": "14.333333333333334px",
      "lineHeight": 1.375,
      "daySize": "22.86px"
    },
    "medium": {
      "fontSize": "15px",
      "lineHeight": 1.45,
      "daySize": "45.71px"
    },
    "large": {
      "fontSize": "17px",
      "lineHeight": 1.11,
      "daySize": "91.43px"
    }
  },
  "checkBox": {
    "size": "20px",
    "toggle": {
      "radius": "20px",
      "size": "40px",
      "extend": ({ checked }) => `
        ${checked && "background-color:#00C781"}
      `,
      "knob": {
        "extend": ({ checked }) => `
          ${checked && "background:#00873D!important"}
        `,
      }
    },
    "check": {
      "radius": "4px"
    }
  },
  "clock": {
    "analog": {
      "hour": {
        "width": "7px",
        "size": "20px"
      },
      "minute": {
        "width": "3px",
        "size": "10px"
      },
      "second": {
        "width": "3px",
        "size": "8px"
      },
      "size": {
        "small": "60px",
        "medium": "80px",
        "large": "120px",
        "xlarge": "180px",
        "huge": "240px"
      }
    },
    "digital": {
      "text": {
        "xsmall": {
          "size": "13.666666666666666px",
          "height": 1.5
        },
        "small": {
          "size": "14.333333333333334px",
          "height": 1.43
        },
        "medium": {
          "size": "15px",
          "height": 1.375
        },
        "large": {
          "size": "15.666666666666666px",
          "height": 1.167
        },
        "xlarge": {
          "size": "16.333333333333332px",
          "height": 1.1875
        },
        "xxlarge": {
          "size": "17.666666666666668px",
          "height": 1.125
        }
      }
    }
  },
  "heading": {
    "level": {
      "1": {
        "small": {
          "size": "18px",
          "height": "23px",
          "maxWidth": "353px"
        },
        "medium": {
          "size": "20px",
          "height": "25px",
          "maxWidth": "407px"
        },
        "large": {
          "size": "26px",
          "height": "31px",
          "maxWidth": "513px"
        },
        "xlarge": {
          "size": "31px",
          "height": "36px",
          "maxWidth": "620px"
        }
      },
      "2": {
        "small": {
          "size": "17px",
          "height": "22px",
          "maxWidth": "340px"
        },
        "medium": {
          "size": "19px",
          "height": "24px",
          "maxWidth": "380px"
        },
        "large": {
          "size": "21px",
          "height": "26px",
          "maxWidth": "420px"
        },
        "xlarge": {
          "size": "23px",
          "height": "28px",
          "maxWidth": "460px"
        }
      },
      "3": {
        "small": {
          "size": "16px",
          "height": "21px",
          "maxWidth": "327px"
        },
        "medium": {
          "size": "18px",
          "height": "23px",
          "maxWidth": "353px"
        },
        "large": {
          "size": "19px",
          "height": "24px",
          "maxWidth": "380px"
        },
        "xlarge": {
          "size": "20px",
          "height": "25px",
          "maxWidth": "407px"
        }
      },
      "4": {
        "small": {
          "size": "16px",
          "height": "21px",
          "maxWidth": "313px"
        },
        "medium": {
          "size": "16px",
          "height": "21px",
          "maxWidth": "327px"
        },
        "large": {
          "size": "17px",
          "height": "22px",
          "maxWidth": "340px"
        },
        "xlarge": {
          "size": "18px",
          "height": "23px",
          "maxWidth": "353px"
        }
      },
      "5": {
        "small": {
          "size": "15px",
          "height": "20px",
          "maxWidth": "293px"
        },
        "medium": {
          "size": "15px",
          "height": "20px",
          "maxWidth": "293px"
        },
        "large": {
          "size": "15px",
          "height": "20px",
          "maxWidth": "293px"
        },
        "xlarge": {
          "size": "15px",
          "height": "20px",
          "maxWidth": "293px"
        }
      },
      "6": {
        "small": {
          "size": "14px",
          "height": "19px",
          "maxWidth": "287px"
        },
        "medium": {
          "size": "14px",
          "height": "19px",
          "maxWidth": "287px"
        },
        "large": {
          "size": "14px",
          "height": "19px",
          "maxWidth": "287px"
        },
        "xlarge": {
          "size": "14px",
          "height": "19px",
          "maxWidth": "287px"
        }
      }
    }
  },
  "paragraph": {
    "small": {
      "size": "15px",
      "height": "20px",
      "maxWidth": "293px"
    },
    "medium": {
      "size": "15px",
      "height": "20px",
      "maxWidth": "300px"
    },
    "large": {
      "size": "16px",
      "height": "21px",
      "maxWidth": "313px"
    },
    "xlarge": {
      "size": "16px",
      "height": "21px",
      "maxWidth": "327px"
    },
    "xxlarge": {
      "size": "18px",
      "height": "23px",
      "maxWidth": "353px"
    }
  },
  "radioButton": {
    "size": "20px"
  },
  "text": {
    "xsmall": {
      "size": "14px",
      "height": "19px",
      "maxWidth": "287px"
    },
    "small": {
      "size": "15px",
      "height": "20px",
      "maxWidth": "293px"
    },
    "medium": {
      "size": "15px",
      "height": "20px",
      "maxWidth": "300px"
    },
    "large": {
      "size": "16px",
      "height": "21px",
      "maxWidth": "313px"
    },
    "xlarge": {
      "size": "16px",
      "height": "21px",
      "maxWidth": "327px"
    },
    "xxlarge": {
      "size": "18px",
      "height": "23px",
      "maxWidth": "353px"
    }
  },
  "formField": {
    "border": {
      "color": "border",
      "error": {
        "color": {
          "dark": "white",
          "light": "status-critical"
        }
      },
      "position": "inner",
      "side": "bottom"
    },
    "content": {
      "pad": "small"
    },
    "disabled": {
      "background": {
        "color": "status-disabled",
        "opacity": "medium"
      }
    },
    "error": {
      "color": "status-critical",
      "margin": {
        "vertical": "xsmall",
        "horizontal": "small"
      }
    },
    "help": {
      "color": "dark-3",
      "margin": {
        "start": "small"
      }
    },
    "info": {
      "color": "text-xweak",
      "margin": {
        "vertical": "xsmall",
        "horizontal": "small"
      }
    },
    "label": {
      "margin": {
        "vertical": "xsmall",
        "horizontal": "small"
      }
    },
    "margin": {
      "bottom": "small"
    },
    "round": "4px"
  }
});


export default function App({ children }) {
  return (
    <Grommet full theme={theme}>
      <Toast />
      <Loading />
      <Alert />
      {children}
    </Grommet>
  );
}
