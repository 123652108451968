import axios from 'axios';
import { API_URL, authHeader } from './api';

const ENDPOINT_URL = API_URL + 'service/';

class MenuService {

  getServices(businessId) {
    return axios.get(ENDPOINT_URL + businessId + "/list", { headers: authHeader() });
  }

  getService(businessId, serviceId) {
    return axios.get(ENDPOINT_URL + businessId + "/" + serviceId, { headers: authHeader() });
  }

  addService(businessId, service) {
    return axios.post(ENDPOINT_URL + businessId, service, { headers: authHeader() });
  }

  deleteService(businessId, serviceId) {
    return axios.delete(ENDPOINT_URL + businessId + "/" + serviceId, { headers: authHeader() });
  }

  //hairStyleIds : [<string>, <string>, ...]
  deleteServices(businessId, serviceIds) {
    return axios.post(ENDPOINT_URL+ businessId + "/deletemany", serviceIds, { headers: authHeader() });
  }

  updateService(businessId, service) {
    return axios.patch(ENDPOINT_URL + businessId, service, { headers: authHeader() } );
  }

  updateOrder(businessId, serviceIds) {
    return axios.patch(ENDPOINT_URL + businessId + "/order", serviceIds, { headers: authHeader() });
  }

  uploadPhoto(businessId, serviceId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ENDPOINT_URL + businessId + "/uploadPhoto" + serviceId,
                      formData,
                      { headers: {...authHeader(), 'Content-Type': 'multipart/form-data' }});
  }
}

export default new MenuService();
