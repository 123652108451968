import React from 'react';
// import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';

export default function CustomPhoneInput(props) {

  const { value, onChange } = props;

  return (
    <PhoneInput
      defaultCountry="US"
      placeholder="Enter phone number"
      value={value}
      onChange={onChange}/>
  );
};

CustomPhoneInput.propTypes = {};
CustomPhoneInput.defaultProps = {};