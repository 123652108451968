//export const API_URL = "http://localhost:4444/v1/";
export const API_URL = "https://api.myafrohair.app/v1/";

export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { 'x-access-token': user.accessToken };
  } else {
    return {};
  }
}