import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Header, Button, Heading, Text, Image } from 'grommet';
import { Trash, Edit, Add, Menu } from 'grommet-icons';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast'
import { AddHairStyle } from './';
import HairStyleService from "../../services/hair-style.service";
import { useFetchHairStyles } from './redux/hooks';
import { presentAlertPrompt } from '../common/Alert';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PhotoService from "../../services/photo.service";

export default function SettingsHairStyles(props) {

  const { hairStyles } = useFetchHairStyles();
  const [ list, setList ] = useState([]);
  const [ hairStyleId, setHairStyleId ] = useState(0);
  const [ openEdit, setOpenEdit ] = useState(false);

  useEffect(() => {
    setList(hairStyles || []);
  }, [hairStyles]);


const DragHandle = sortableHandle(() => <Menu />);

const SortableItem = sortableElement(({value}) => (
  <Box direction="row" gap="medium" justify="between">
    <Box alignContent="center" className="hair-styles-column">
      <DragHandle />
    </Box>
    <Box direction="row" gap="medium" width="400px" className="hair-styles-column" align="start">
        <Box height="70px" width="70px">
          <Image height="70px" width="70px"
                  fit="cover"
                  src={PhotoService.getURL(value.photo) || require('../../images/nophoto.jpg')}
                  className="hair-styles-image" onClick={() => handleEdit(value._id)} />
        </Box>
        <Box gap="small" width="330px">
          <Text weight="bold">{value.name}</Text>
          <Text truncate color="dark-6" style={{fontStyle:'italic'}}>{value.description}</Text>
        </Box>
    </Box>
    <Box direction="row" gap="medium" className="hair-styles-column">
      <Button icon={<Edit size="small" />} size="small" label="Edit" alignSelf="center"
                primary color="status-warning"
                onClick={() => handleEdit(value._id)}
                />
      <Button icon={<Trash size="small" />} size="small" label="Remove" alignSelf="center"
                primary color="status-critical"
                onClick={() => handleRemoveHairStyle(value._id)}
                />
    </Box>
  </Box>
));

const SortableContainer = sortableContainer(({children}) => {
  return <Box gap="small" align="start" fill="horizontal">{children}</Box>;
});

  const onRefresh = () => {
    console.log("refresh");
    HairStyleService.getHairStyles().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEditModalClose = (refresh) =>
  {
    if (refresh)
      onRefresh();

    setOpenEdit(false);
  }

  const handleRemoveHairStyle = (hairStyleId) => {
    presentAlertPrompt({title:"Are you sure?",
                message:"Do you really want to delete this style?",
                onOK:()=>{removeHairStyle(hairStyleId)},
                buttonOKText:"Delete",
                buttonOKColor:"status-critical"
    });
  }

  const removeHairStyle = (hairStyleId) => {
    HairStyleService.deleteHairStyle(hairStyleId).then(
      (response) => {
          onRefresh();
          presentToastSuccess("Hair style successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  const handleEdit = (hairStyleId) => {
    setHairStyleId(hairStyleId);
    setOpenEdit(true);
  }

  const handleAdd = () => {
    setHairStyleId(0);
    setOpenEdit(true);
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newSortedList = arrayMove(list, oldIndex, newIndex);
    const listIds = newSortedList.map(x=>x._id);
    HairStyleService.updateOrder(listIds).then(
      (response) => {
          setList(newSortedList);
          onRefresh();
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );

  };

  return (
      <Box gap="medium" justify="start" className="settings-hair-styles" flex="grow">
        <Heading alignSelf="start" level="2">Hair Style</Heading>
        <Header align="center"
                direction="row"
                justify="between"
                gap="medium"
                fill="horizontal">
          <Button primary icon={<Add size="small" />} size="small" label="Add" onClick={handleAdd} />
          { openEdit &&
            <AddHairStyle hairStyleId={hairStyleId} onClose={handleEditModalClose} />
          }
        </Header>
        <SortableContainer onSortEnd={onSortEnd} useDragHandle >
          {list.map((value, index) => (
            <SortableItem key={`style-${value._id}`} index={index} value={value} />
          ))}
        </SortableContainer>
      </Box>
  );
};

SettingsHairStyles.propTypes = {};
SettingsHairStyles.defaultProps = {};
