import React, { useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, Text } from "grommet";
import { Add } from 'grommet-icons';

const defaultValues = {
  username:"",
  email:""
}

export default function AddUser(props) {
  const { onSubmit } = props;
  const [newUser, setNewUser] = useState(defaultValues);
  const [open, setOpen] = useState();
  const onOpen = () => {setNewUser({});setOpen(true);}
  const onClose = () => setOpen(undefined);

  function handleInputChange({target:{name, value}}) {
    var values = {...newUser};
    values[name] = value;
    setNewUser(values);
  }
  const handleAdd = () =>
  {
    onClose();
    onSubmit(newUser);
  }

  return (
    <div>
    
      <Button primary icon={<Add size="small" />} size="small" label="Add" onClick={onOpen} />
      {open && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              Add FrontDesk User
            </Heading>
            <Text>Enter a username</Text>
            <TextInput name="username" value={newUser.username} onChange={handleInputChange} />
            <Text>Enter an email address</Text>
            <TextInput name="email" value={newUser.email} onChange={handleInputChange} />
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button label="Add" onClick={handleAdd} primary color="status-ok"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </div>
  );
};

AddUser.propTypes = {};
AddUser.defaultProps = {};
