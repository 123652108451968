import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_FETCH_HAIR_STYLES_BEGIN,
  DASHBOARD_FETCH_HAIR_STYLES_SUCCESS,
  DASHBOARD_FETCH_HAIR_STYLES_FAILURE,
  DASHBOARD_FETCH_HAIR_STYLES_DISMISS_ERROR
} from './constants';
import HairStyleService from "../../../services/hair-style.service";

export function fetchHairStyles(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_FETCH_HAIR_STYLES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      HairStyleService.getHairStyles().then(
        (res) => {
          dispatch({
            type: DASHBOARD_FETCH_HAIR_STYLES_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_FETCH_HAIR_STYLES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissFetchHairStylesError() {
  return {
    type: DASHBOARD_FETCH_HAIR_STYLES_DISMISS_ERROR,
  };
}

export function setHairStyles(hairStyles) {
  return {
    type: DASHBOARD_FETCH_HAIR_STYLES_SUCCESS,
    hairStyles
  };
}

export function useFetchHairStyles() {
  const dispatch = useDispatch();

  const { hairStyles, fetchHairStylesPending, fetchHairStylesError } = useSelector(
    state => ({
      hairStyles: state.dashboard.hairStyles,
      fetchHairStylesPending: state.dashboard.fetchHairStylesPending,
      fetchHairStylesError: state.dashboard.fetchHairStylesError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchHairStyles(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchHairStylesError());
  }, [dispatch]);

  const boundSet = useCallback(() => {
    return dispatch(setHairStyles());
  }, [dispatch]);

  return {
    hairStyles,
    fetchHairStyles: boundAction,
    setHairStyles: boundSet,
    fetchHairStylesPending,
    fetchHairStylesError,
    dismissFetchHairStylesError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_HAIR_STYLES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchHairStylesPending: true,
        fetchHairStylesError: null,
      };

    case DASHBOARD_FETCH_HAIR_STYLES_SUCCESS:
      // The request is success
      return {
        ...state,
        hairStyles: action.data,
        fetchHairStylesPending: false,
        fetchHairStylesError: null,
      };

    case DASHBOARD_FETCH_HAIR_STYLES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchHairStylesPending: false,
        fetchHairStylesError: action.data.error,
      };

    case DASHBOARD_FETCH_HAIR_STYLES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchHairStylesError: null,
      };

    default:
      return state;
  }
}
