import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//import PropTypes from 'prop-types';
import { Image as ImageGrommet} from 'grommet';
import { FileDrop } from 'react-file-drop';

function PhotoUploader(props, ref) {

  const { onChange, fill, photo, width, height, label, blackbg } = props;
  const [image, setImage] = useState(photo);
  const [dropping, setDropping] = useState(null);
  const fileInput = useRef(null);

  useEffect(()=>{
    setImage(photo);
  },[photo]);

  useImperativeHandle(ref, () => ({
    hasImage: () => {
      return (!!image);
    },
    clear: () => {
      setImage(null);
      fileInput.current.value = null;
    }
  }));

  function handleFileSelect(files, evt) {
      console.log("files", files);
      if (files.length === 0) return;
      
      var reader = new FileReader();
      reader.onloadend = function(e) {
          setImage(e.target.result);
      };
      reader.readAsDataURL(files[0]);
      if (onChange) onChange(files[0]); 
  }

  return (
    <div>
      {label && <div className="label">{label}</div>}
      <div className={`home-image-loader ${fill?"fill":""}`} style={{height:height, width:width||200}} onClick={()=>{fileInput.current.click()}}>
          <FileDrop onDrop={(files, event) => handleFileSelect(files, event)}
            onFrameDragEnter={(event) => setDropping(true)}
            onFrameDragLeave={(event) => setDropping(false)}
            onFrameDrop={(event) => setDropping(false)}
            className={`file-drop ${blackbg?"blackbg":""}`}
          >
            {(!image || dropping) ? 
              <div className="drop-text"><strong>Drop a file here!<br />or click to browse files</strong>
              </div>
                : <ImageGrommet src={image} className="image"/>}
          </FileDrop>
          <input type="file" id="files" ref={fileInput} className="file-input" onChange={(event)=>handleFileSelect(event.target.files, event)} />
      </div>
    </div>
  );
};

export default forwardRef(PhotoUploader);