import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Heading, Layer, Text, TextArea, FormField } from "grommet";
import { presentLoading, dismissLoading } from '../common/Loading';
import { presentToastSuccess, presentToastErrorContent } from '../common/Toast';
import HairStyleService from "../../services/hair-style.service";
import PhotoService from "../../services/photo.service";
import { PhotoUploader } from './';

const defaultValues = {
  _id:0,
  name:""
}

export default function AddHairStyle(props) {
  const { hairStyleId, onClose } = props;
  const [hairStyle, setHairStyle] = useState(defaultValues);
  const [photo, setPhoto] = useState(undefined);

  function handleInputChange({target:{name, value}}) {
    var values = {...hairStyle};
    values[name] = value;
    setHairStyle(values);
  }

  const handleAdd = () =>
  {
    if (hairStyleId === 0)
    {
      HairStyleService.addHairStyle(hairStyle).then(
        async (response) => {
            const hairStyle = response.data && response.data.hairStyle;
            if (hairStyle)
              await uploadPhoto(hairStyle._id);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      HairStyleService.updateHairStyle(hairStyle).then(
        async (response) => {
            await uploadPhoto(hairStyleId);
            onClose(true);
            presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  }

  const uploadPhoto = async (typeId) =>
  {
    if (!photo) return;
    await HairStyleService.uploadPhoto(typeId, photo).then(
      (response) => {
        if (response.data.success)
        {
          //presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }

  useEffect(() => {
    if (hairStyleId !== 0)
    {
      presentLoading();
      HairStyleService.getHairStyle(hairStyleId).then(
        (response) => {
          dismissLoading();
          const hairStyle = response.data;
          if (hairStyle._id)
          {
            setHairStyle(hairStyle);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
    else
    {
      setHairStyle(defaultValues);
    }
  }, [hairStyleId]);

  return (
        <Layer position="center" onClickOutside={()=>onClose(false)} onEsc={onClose}>
          <Box pad="medium" gap="small" width="large">
            <Heading level={3} margin="none">
              Add Hair Style
            </Heading>
            <Box direction="row"  gap="medium">
              <Box gap="medium">
                <FormField label="Name" width="medium">
                  <TextInput name="name" value={hairStyle.name} onChange={handleInputChange} />
                </FormField>
                <Text>Description</Text>
                <TextArea style={{height:150}} name="description" value={hairStyle.description} onChange={handleInputChange} />
              </Box>
              <Box alignSelf="center">
                <PhotoUploader height={240} width={200} 
                             label="Photo (jpg, 400x480, Quality 80)" 
                             onChange={setPhoto} photo={PhotoService.getURL(hairStyle.photo) || ""} />
              </Box>
            </Box>
            <Box as="footer" gap="small" direction="row" align="center"
              justify="end" pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={()=>onClose(false)} color="dark-3" />
              <Button label={hairStyleId===0?"Add":"Update"} onClick={handleAdd} primary color="status-ok" />
            </Box>
          </Box>
        </Layer>
  );
};

AddHairStyle.propTypes = {};
AddHairStyle.defaultProps = {};
