import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Form, TextInput, FormField, Button, Text} from 'grommet';
import AuthService from "../../services/auth.service";
import history from '../../common/history';
import { presentToastErrorContent, presentToastSuccess } from '../common/Toast';

// import PropTypes from 'prop-types';

export default function ChangePwdComponent(props) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const onPasswordChange = event => setPassword(event.target.value);
  const onPassword2Change = event => setPassword2(event.target.value);

  const handleLogin = (e) => {

    if (password2 !== password)
    {
      setErrorMessage("Password don't match");
      return;
    }

    AuthService.change(token, password).then(
      () => {
        presentToastSuccess("Your password has been changed. Please login again.")
        history.push("/sign-in");
        window.location.reload();
      },
      error => {
          presentToastErrorContent(error);
      }
    );
  }

  return (
    <Form {...props} onSubmit={handleLogin}>
      <Box gap="medium" >
        <FormField
          label='Password'
          htmlFor='password'
        >
            <TextInput
              id='password'
              placeholder='Enter your new password'
              type="password"
              onChange={onPasswordChange}
            />
        </FormField>

        <FormField
          label='Confirm Password'
          htmlFor='password2'
        >
            <TextInput
              id='password2'
              placeholder='Confirm your new password'
              type="password"
              onChange={onPassword2Change}
            />
        </FormField>
        {errorMessage && (
              <Box pad={{ horizontal: 'small' }}>
                <Text color="status-error">{errorMessage}</Text>
              </Box>
            )}
        <Button label="Submit" primary type="submit" alignSelf="center" disabled={password===""}/>
      </Box>
    </Form>
  );
};

ChangePwdComponent.propTypes = {};
ChangePwdComponent.defaultProps = {};
