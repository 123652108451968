import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import axios from 'axios';

Map.propTypes = {
  onLoad: PropTypes.func
};
Map.defaultProps = {};

export default function Map(props) {
  const { onLoad, onChange, coords, ...rest } = props;
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    if (!map) 
    {
      mapboxgl.accessToken = 'pk.eyJ1IjoiaGFyb2xkaDE3IiwiYSI6ImNrOTdvY25qMTE4dDkza3AzZzd5bmFzN3UifQ.z_s1dPrcZt8AQ40pq4Ja-w';
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", 
        center: [-5, 34],
        zoom: 2
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
        if (onLoad)
            onLoad(map);
      });

      // map.on('click', (e) => {
      //   onChange({lngLat:e.lngLat});
      //   geocoder.clear();
      // });  

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        //localGeocoder: coordinatesGeocoder,
        language: 'en-US',
        type: 'address'
      });

      geocoder.on('result', async (result)=>{
        if (result.result)
        {        
          let geocode = result.result;
          let address = (geocode.address ? geocode.address + " " : "") + geocode["text_en-US"];
          let city, zipCode, state, country;
          geocode.context.forEach((obj) => 
          {
            let type = obj.id.split(".")[0];
            if (type === "postcode")
              zipCode = obj["text_en-US"];
            else if (type === "place")
              city = obj["text_en-US"];
            else if (type === "region")
              state = obj["short_code"].split("-")[1];
            else if (type === "country")
              country = obj["short_code"].toUpperCase();
          });

          var longitude = result.result.center[0];
          var latitude = result.result.center[1];
          var urlTimeZone = 'https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/' + longitude + ',' + latitude + '.json?access_token=' + mapboxgl.accessToken;

          var response = await axios.get(urlTimeZone);
          var timezone = response && 
                         response.data && 
                         response.data.features && 
                         response.data.features.length && 
                         response.data.features[0].properties && 
                         response.data.features[0].properties.TZID;

          onChange({ longitude, latitude, timezone,
                    address, city, state, zipCode, country });

          updateMarker(map, marker, setMarker, result.result.center);
          map.setZoom(10);
        }
        });

      map.addControl(geocoder, 'top-left');

      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));
    }
    else 
    {
      // setTimeout(()=>{
      //   map.resize();
      //   // if (coords)
      //   //   updateMarker(map, marker, setMarker, coords);
      //   }, 100);
    }
  }, [map, marker]);

  useEffect(() => {
    if (map) 
    {
      map.resize();
      updateMarker(map, marker, setMarker, coords);
    }
  }, [map, coords]);

  return (
    <Box {...rest}>
      <div className="map-wrapper">
        <div ref={el => (mapContainer.current = el)} className="mapContainer" />
        <i className="top"></i>
        <i className="right"></i>
        <i className="bottom"></i>
        <i className="left"></i>
        <i className="top left"></i>
        <i className="top right"></i>
        <i className="bottom left"></i>
        <i className="bottom right"></i>
    </div>
    </Box>
  );
};

const updateMarker = (map, marker, setMarker, lngLat, options = {}) => {
  if (marker && !lngLat.lng && !lngLat.lat)
  {
    marker.remove();
  }
  
  if ((!lngLat.lng && !lngLat.lat)) 
  {
    return;
  }

  map.flyTo({ center: lngLat });
  if (!marker) {
    let newMarker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map) 
    setMarker(newMarker);
  }
  else 
  {
    marker.setLngLat(lngLat);
  }
};


/* given a query in the form "lng, lat" or "lat, lng" returns the matching
* geographic coordinate(s) as search results in carmen geojson format,
* https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
*/
var coordinatesGeocoder = function(query) {
  // match anything which looks like a decimal degrees coordinate pair
  var matches = query.match(
    /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
    return null;
  }
 
  function coordinateFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature'
    };
  }

  var coord1 = Number(matches[1]);
  var coord2 = Number(matches[2]);
  var geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  return geocodes;
};